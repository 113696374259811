<template>
  <el-main class="main">
    <div class="title">{{ $t('usage.title') }}</div>
    <div class="filters">
      <el-radio-group v-model="users" size="mini" class="users-select" @input="changeUsers">
        <el-radio-button label="self">{{ $t('usage.users.self') }}</el-radio-button>
        <el-radio-button label="others">{{ $t('usage.users.others') }}</el-radio-button>
      </el-radio-group>
      <el-date-picker
          v-model="month"
          type="month"
          value-format="yyyy-MM"
          @change="changeDate"
      >
      </el-date-picker>
    </div>
    <hr>
    <el-row :gutter="40">
      <el-col :span="15">
        <div>{{ $t('usage.chart.title1') }} {{ monthly_spent }}</div>
        <div id="usage-chart1" class="chart-box"></div>
        <div>{{ $t('usage.chart.title2') }}</div>
        <div id="usage-chart2" class="chart-box"></div>
      </el-col>
      <el-col :span="9">
        <div>{{ $t('usage.chart.title3') }}</div>
        <div class="mt20">
          <el-progress type="circle" :percentage="usage_percent" :stroke-width="20" stroke-linecap="butt"></el-progress>
        </div>
        <div class="mt20">
          <el-table :data="balance_list">
            <el-table-column :label="$t('usage.table.title1')" align="center" key="insertTime" prop="insertTime"
                             :show-overflow-tooltip="true"/>
            <el-table-column :label="$t('usage.table.title2')" align="center" key="status" prop="status" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-tag
                    :type="scope.row.is_expired? 'danger' : 'success'"
                    disable-transitions>{{ scope.row.status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column :label="$t('usage.table.title3')" align="center" key="balance_total" prop="balance_total" :show-overflow-tooltip="true"/>
            <el-table-column :label="$t('usage.table.title4')" align="center" key="validDate" prop="validDate"
                             :show-overflow-tooltip="true"/>
          </el-table>
        </div>
        <div class="mt20">
          <div>{{ $t('usage.taskTable.title') }}：</div>
          <el-table :data="task_statistics_list">
            <el-table-column :label="$t('usage.taskTable.column1')" align="center" key="taskTypeName" prop="taskTypeName"></el-table-column>
            <el-table-column :label="$t('usage.taskTable.column2')" align="center" key="templateName" prop="templateName"></el-table-column>
            <el-table-column :label="$t('usage.taskTable.column3')" align="center" key="total" prop="total"></el-table-column>
          </el-table>
        </div>
        <div style="display: flex;flex-direction: row-reverse">
          <el-tooltip placement="left-start">
            <div slot="content">
              {{ $t('usage.explain.expend') }}：<br/>
              brief：<br/>
              3.1：5~20<br/>
              4.0：5~12<br/>
              6.1：5~24<br/><br/>
              {{ $t('usage.explain.plan') }}：<br/>
              3.1：98~245<br/>
              4.0：90~380<br/>
              6.1：186~354<br/><br/>
              {{ $t('usage.explain.translation') }}：16~20
            </div>
            <i class="el-icon-question" style="cursor: pointer"></i>
          </el-tooltip>

        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import * as echarts from 'echarts/core';
import {GridComponent, TooltipComponent} from 'echarts/components';
import {BarChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';

export default {
  name: "Usage",
  data: function () {
    return {
      users: 'self',
      month: '',
      monthly_spent: 0,
      usage_percent: 0,
      balance_list_raw: [],
      task_statistics_list_raw: []
    }
  },
  watch: {
    '$route': function (to) {
      let users = to.query['users']
      if (!users) {
        users = 'self'
      }
      this.users = users
      this.loadChart1()
    }
  },
  mounted() {
    let today = new Date();
    this.month = this.dateformat(today, "YYYY-MM")
    let users = this.$route.query['users']
    if (!users) {
      users = 'self'
    }
    this.users = users
    this.loadChart1()
    this.loadChart2()
    this.loadPercent()
    this.loadBalanceTable()
    this.loadTaskStatisticsTable()
  },
  computed:{
    balance_list() {
      let balance_list = this.balance_list_raw
      balance_list.forEach(item => {
        let current_date = new Date();
        current_date.setHours(0, 0, 0, 0)
        let expire_date = new Date(item.validDate)
        if (current_date > expire_date) {
          item.status = this.$t('usage.table.status1')
          item.is_expired = true
        } else {
          item.status = this.$t('usage.table.status2')
          item.is_expired = false
        }
        item.balance_total=Math.round(item.balance)+"/"+Math.round(item.total)
      })
      return balance_list
    },
    task_statistics_list(){
      if (this.$i18n.locale === 'zh-tw') {
        this.task_statistics_list_raw.forEach((item) => {
          if (item.taskTypeName){
            item.taskTypeName = this.translation(item.taskTypeName)
          }
          if (item.templateName){
            item.templateName = this.translation(item.templateName)
          }
        })
      }
      return this.task_statistics_list_raw
    }
  },
  methods: {
    loadPercent: function () {
      let me = this
      this.axios.post("/balance/dept").then((res) => {
        me.usage_percent = res.data
      })
    },
    loadChart1: function () {
      let me = this
      let path = this.users
      let params = {
        startDate: this.month + "-01",
        endDate: this.getLastDayOfMonth(this.month)
      }
      this.axios.post("/balance/" + path, params).then((res) => {
        me.monthly_spent = res.data.seriesData.reduce((a, b) => a + b, 0)
        me.renderChart('usage-chart1', res.data)
      })
    },
    loadChart2: function () {
      let me = this
      let params = {
        startDate: this.month + "-01",
        endDate: this.getLastDayOfMonth(this.month)
      }
      this.axios.post("/balance/total", params).then((res) => {
        me.renderChart('usage-chart2', res.data)
      })
    },
    loadBalanceTable: function () {
      let me = this
      this.axios.post("/balance/dept/list").then((res) => {
        me.balance_list_raw=res.data
      })
    },
    loadTaskStatisticsTable:function (){
      let me = this
      this.axios.post("/balance/task/statistics").then((res) => {
        me.task_statistics_list_raw=res.data
      })
    },
    renderChart: function (id, data) {
      echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);
      let chartDom = document.getElementById(id);
      let myChart = echarts.init(chartDom);
      let option = {
        xAxis: {
          type: 'category',
          data: data.xAxisData
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          show: true
        },
        series: [
          {
            data: data.seriesData,
            type: 'bar'
          }
        ]
      };
      option && myChart.setOption(option);
    },
    getLastDayOfMonth: function (yearMonth) {
      const [year, month] = yearMonth.split('-').map(Number);
      const date = new Date(year, month, 0);
      return this.dateformat(date, "YYYY-MM-DD")
    },
    changeDate: function () {
      this.loadChart1()
      this.loadChart2()
    },
    changeUsers: function () {
      this.loadChart1()
    }
  }
}
</script>

<style scoped>

.main {
  padding: 0.2rem;
  font-size: 0.18rem;
  color: #000000;
  overflow-x: hidden;
}

.filters {
  display: flex;
  align-items: center;
}

.users-select {
  flex: 1;
}

.chart-box {
  width: 100%;
  height: 300px;
}

.mt20 {
  margin-top: 20px;
}
</style>
